import AppImage from '@theme/components/atom/AppImage'
import Wysiwyg from '@theme/components/utils/Wysiwyg'

export default {
  components: {
    AppImage,
    Wysiwyg,
  },
  props: {
    banner: {
      type: Object,
      required: true,
    },
  },
}
